<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사고일자 -->
          <c-datepicker
            name="accDt"
            :range="true"
            label="사고일자"
            v-model="searchParam.accDt"
          ></c-datepicker>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="동종업계 사고사례 목록"
      tableId="accidentSeq"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group outline>
            <c-btn label="사례 등록" :editable="editable" icon="add" @btnClicked="onItemClick" />
            <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </div>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
  <!-- <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <c-table
          ref="table"
          title="동종업계 사고사례 목록"
          tableId="accidentSeq"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          :merge="grid.merge"
          @rowClick="rowClick"
        >
          <template slot="table-button">
            <div>
              <q-btn-group outline>
                <c-btn label="LBLADD" :editable="editable" icon="add" @btnClicked="onItemClick" />
                <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
              </q-btn-group>
            </div>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
        <c-card title="동종업계 사고사례 기본정보" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline >
              <c-btn v-if="editable" label="LBLSAVE" icon="save" @btnClicked="save" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-6">
              <c-text
                label="사고명"
                :required="true"
                name="col1"
                v-model="sameAccident.col1"
              />
            </div>
            <div class="col-6">
              <c-text
                label="사고업체"
                :required="true"
                name="col2"
                v-model="sameAccident.col2"
              />
            </div>
            <div class="col-6">
              <c-text
                label="장소"
                :required="true"
                name="col3"
                v-model="sameAccident.col3"
              />
            </div>
            <div class="col-6">
              <c-datepicker
                label="사고일자"
                name="col4"
                v-model="sameAccident.col4"
              />
            </div>
            <div class="col-12">
              <c-textarea
                label="피해현황"
                :editable="editable"
                name="col5"
                v-model="sameAccident.col5" />
            </div>
            <div class="col-12">
              <c-textarea
                label="원인"
                :editable="editable"
                name="col6"
                v-model="sameAccident.col6" />
            </div>
            <div class="col-12">
              <c-textarea
                label="내용"
                :editable="editable"
                name="col7"
                v-model="sameAccident.col7" />
            </div>
            <div class="col-3">
              <c-plant
                :required="true"
                :editable="editable"
                type="edit"
                name="plantCd"
                v-model="sameAccident.plantCd" />
            </div>
            <div class="col-12">
              <c-upload
                :editable="editable"
                label="동종업계 사고사례 사진첨부">
              </c-upload>
            </div>
          </template>
        </c-card>
      </div>
    </div> -->
</template>

<script>
import CDatepicker from '../../../../components/CDatepicker.vue';
export default {
  components: { CDatepicker },
  name: "same-accidents",
  data() {
    return {
      grid: {
        // merge: [{ index: 0, colName: "plantName" }],
        columns: [
        //   {
        //     name: "plantName",
        //     field: "plantName",
        //     label: "LBLPLANT",
        //     align: "center",
        //     sortable: true,
        //   },
          {
            name: "accidentNm",
            field: "accidentNm",
            label: "사고명",
            align: "center",
            sortable: true,
            type: "link",
          },
          {
            name: "accidentVendorNm",
            field: "accidentVendorNm",
            label: "사고업체",
            align: "center",
            sortable: true,
          },
          {
            name: "accidentArea",
            field: "accidentArea",
            label: "장소",
            align: "center",
            sortable: true,
          },
          {
            name: "accDt",
            field: "accDt",
            label: "사고일자",
            align: "center",
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        accDt: [],
      },
      editable: true,
      searchUrl: "",
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      isResult: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.grid.data = [
        {
          accidentSeq: 1,
          plantName: '사업장1',
          accidentNm: "떨어짐",
          accidentArea: '장소1',
          accidentVendorNm: '업체1',
          accDt: "2021-08-19",
        },
        {
          accidentSeq: 2,
          plantName: '사업장2',
          accidentNm: "미끄러짐",
          accidentArea: '장소2',
          accidentVendorNm: '업체2',
          accDt: "2021-09-19",
        },
      ];
    },
    /* eslint-disable no-unused-vars */ 
    linkClick(row, col, index) {
      this.popupOptions.title = "동종업계 사고사례 상세"; // 공정사고 상세
      this.popupOptions.param = {
        row: row,
      };
      this.popupOptions.target = () =>
        import(`${"./sameAccidentsDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.width = '60%';
      this.popupOptions.isFull = false;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    onItemClick() {
      this.popupOptions.target = () =>
      import(`${"./sameAccidentsDetail.vue"}`);
      this.popupOptions.title = '동종업계 사고사례 상세'; // 공정사고 상세
      this.popupOptions.param = null;
      this.popupOptions.isFull = false;
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
  },
};
</script>
